import "./App.css";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { Suspense } from "react";
import Home from "./components/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toast";

function App() {
  return (
    <div className="min-h-screen w-full dark:bg-gray-800">
      <BrowserRouter>
        <ToastContainer delay={3000} />
        <Header />
        <Suspense>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
