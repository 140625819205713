import React from "react";

export default () => {
  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          Most Trusted Anonymous Email Sender
        </h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          Your Secure, Anonymous Email Solution. With encryption, no data saved,
          and no traces. Plus, track message status discreetly with read
          receipts. Securely send anonymous emails.
        </p>
        <div class="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
          <span class="font-semibold text-gray-100 uppercase">FEATURES</span>
          <div class="flex flex-wrap justify-center items-center mt-4 text-gray-500 sm:justify-between">
            <div class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400 text-green-500 font-bold">
              Fully Secured <i class="fa fa-check text-green-500 " />
            </div>
            <div class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400 text-green-500 font-bold">
              100% Anonymous <i class="fa fa-check text-green-500 " />
            </div>
            <div class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400 text-green-500 font-bold">
              No Data Saved <i class="fa fa-check text-green-500 " />
            </div>
            <div class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400 text-green-500 font-bold">
              No Registration <i class="fa fa-check text-green-500 " />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
