import React from "react";
import Hero from "../Hero";
import Email from "../Email";

export default () => {
  return (
    <div className="flex flex-col">
      <Hero />
      <Email />
    </div>
  );
};
