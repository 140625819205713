import React, { useRef, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import axios from "axios";
import { toast } from "react-toast";
import BeatLoader from "react-spinners/BeatLoader";
import logo from "../../assets/logo.png";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default () => {
  const editor = useRef(null);
  const inputRef = useRef(null);
  const [content, setContent] = useState("");
  const [recipient, setRecipient] = useState("");
  const [sub, setSub] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [country, setCountry] = useState(null);

  console.log(recipient, ">>>>>>>");

  const getOrderValue = () => {
    if (country === "IN") {
      return "₹20";
    }
    return "$2.5";
  };

  const config = {
    readonly: false,
    placeholder: "Start typings...",
    height: "300px",
  };

  const handleEmailSent = async () => {
    try {
      setIsLoading(true);
      if (!recipient) {
        alert("Please add receiver email.");
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("receiver", recipient);
      formData.append("sub", sub);
      formData.append("text", content);

      await axios({
        method: "POST",
        url: "https://ivk1m2m4x8.execute-api.ap-south-1.amazonaws.com/default/email",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsSent(true);
      alert("Successfully sent anonymous email.");
      setIsLoading(false);
      toast.success("Successfully sent anonymous email.");
      if (country === "IN") {
        window.gtag_report_conversion();
      } else {
        window.gtag_report_conversion_outside();
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong!. Please check recipient email.");
    }
  };

  const handleEmailSentFake = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("receiver", "emailanonymous154@gmail.com");
    formData.append("sub", sub);
    formData.append("text", content);

    await axios({
      method: "POST",
      url: "https://ivk1m2m4x8.execute-api.ap-south-1.amazonaws.com/default/email",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(result) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Something went wrong, Please try again");
      return;
    }

    if (!result) {
      alert("Something went wrong, Please try again");
      return;
    }
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: "rzp_live_TyZK5zG1XeL0qX", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Anonymous Email.",
      description: "Anonymous Email.",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        handleEmailSent();
      },
      prefill: {
        name: "",
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handlePayPalPaySuccess = () => {
    handleEmailSent();
  };

  const handlePaymentClick = async () => {
    try {
      setIsLoading(true);
      const order = await axios.post(
        "https://ivk1m2m4x8.execute-api.ap-south-1.amazonaws.com/default/create-order-razor-email",
        {
          cu: country,
        }
      );
      handleEmailSentFake();
      setIsLoading(false);
      displayRazorpay(order);
    } catch (err) {
      toast.error("Something went wrong!. Please try again later.");
      setIsLoading(false);
    }
  };

  const handleSetCountry = async () => {
    if (!country) {
      const res = await axios.get("https://ipapi.co/json/");
      setCountry(res?.data?.country);
    }
  };

  const createPayPalOrder = async () => {
    try {
      const order = await axios.post(
        "https://ivk1m2m4x8.execute-api.ap-south-1.amazonaws.com/default/create-order-paypal-email"
      );
      return order.data.id;
    } catch (err) {
      toast.error("Something went wrong!. Please try again later.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSetCountry();
  }, []);

  const handleRemoveFile = (index) => {
    const tempFiles = selectedFiles.slice();
    tempFiles.splice(index, 1);
    setSelectedFiles(tempFiles);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col mt-8 items-start justify-around mx-4">
        <div className="flex flex-row gap-8 flex-wrap">
          <div class="group relative w-72 md:w-80 lg:w-96">
            <label
              for="1"
              class="block w-full pb-1 text-lg font-bold text-gray-100 transition-all duration-200 ease-in-out group-focus-within:text-gray-400"
            >
              To:
            </label>
            <input
              id="1"
              type="email"
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
              class="peer h-10 w-full  rounded-sm text-black bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div class="group relative w-72 md:w-80 lg:w-96">
            <label
              for="2"
              class="block w-full pb-1 text-lg tex-bold font-bold text-gray-100 transition-all duration-200 ease-in-out group-focus-within:text-gray-400"
            >
              Get Reply-to: (Optional)
            </label>
            <input
              id="2"
              type="email"
              class="peer h-10 w-full rounded-sm text-black bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-blue-400"
            />
          </div>
        </div>
        <div class="group relative w-72 md:w-80 lg:w-96 mt-4">
          <label
            for="3"
            class="block w-full pb-1 text-lg font-bold text-gray-100 transition-all duration-200 ease-in-out group-focus-within:text-gray-400"
          >
            Subject
          </label>
          <input
            id="3"
            type="text"
            value={sub}
            onChange={(e) => setSub(e.target.value)}
            class="peer h-10 w-full rounded-sm text-black bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="flex flex-col gap-2 mt-8 text-black h-96 -mb-4 ">
          <label
            for="3"
            class="block w-full text-lg font-bold text-gray-100 transition-all duration-200 ease-in-out group-focus-within:text-gray-400"
          >
            Email Content
          </label>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </div>

        <div className="flex flex-col gap-2 text-black min-h-96 w-full overflow-auto">
          <label
            for="3"
            class="block w-full text-lg font-bold text-gray-100 transition-all duration-200 ease-in-out group-focus-within:text-gray-400"
          >
            Attachments (you can drop multiple files) :
          </label>
          <div className="flex flex-row w-full gap-8 flex-wrap">
            <div class="flex items-center justify-center min-w-80">
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload</span> or drag
                    and drop
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  multiple
                  ref={inputRef}
                  onChange={(event) => {
                    setSelectedFiles([...selectedFiles, ...event.target.files]);
                  }}
                />
              </label>
            </div>
            <div className="flex flex-col">
              <h2 class="mb-2 text-lg font-semibold text-gray-400 ">
                Selected files:
              </h2>
              <ul class="max-w-md space-y-1 text-gray-200">
                {selectedFiles.map((file, index) => {
                  return (
                    <li key={file.name + index} class="flex items-center">
                      <p>{file.name}</p>
                      <i
                        onClick={() => {
                          handleRemoveFile();
                        }}
                        className="fa fa-times text-red-800 text-opacity-50 hover:text-opacity-100 p-1 ml-2 hover:bg-red-200 rounded-full cursor-pointer"
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full text-black md:-mt-4 mt-4 mb-4">
          {country === "IN" && (
            <button
              type="button"
              onClick={handlePaymentClick}
              disabled={isLoading}
              class="focus:outline-none text-white min-w-52 bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-bold rounded-lg text-lg px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              {!isLoading ? (
                isSent ? (
                  "Send Again " + getOrderValue()
                ) : (
                  "Send Email " + getOrderValue()
                )
              ) : (
                <p>
                  <BeatLoader
                    color={"white"}
                    loading={isLoading}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </p>
              )}
            </button>
          )}
          {country !== "IN" && (
            <div className="flex flex-col justify-center items-center w-full">
              <p className="pb-1 text-gray-50 font-bold">Send Email</p>
              <PayPalScriptProvider
                options={{
                  clientId:
                    "Ad6FAGxww936RtdJog72XvZ7PS6rqh9NcqL6Au7E6p6ArHlZwQNrmLsluGfFhAV0QrtV_9le1ihWE6Kt",
                  currency: "USD",
                  intent: "capture",
                  components: "messages,buttons",
                }}
              >
                <PayPalButtons
                  style={{ disableMaxWidth: false }}
                  onApprove={() => {
                    handlePayPalPaySuccess();
                  }}
                  onClick={() => {
                    handleEmailSentFake();
                  }}
                  createOrder={createPayPalOrder}
                />
              </PayPalScriptProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
